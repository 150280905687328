<template>
  <div>
    <v-alert
      v-if="!profile.allow_events"
      type="error"
    >
      You may not create an event.
    </v-alert>
    <v-alert
      v-else-if="!$online"
      type="error"
    >
      An internet connection is required to create an event.
    </v-alert>
    <div v-else>
      <v-alert
        v-for="error in errorMessages"
        :key="error"
        type="error"
      >
        {{ error }}
      </v-alert>
      <v-form
        ref="form"
        @submit.prevent="submit"
      >
        <v-card flat>
          <v-list subheader>
            <v-list-item>
              <v-text-field
                v-model="summary"
                :disabled="submitting"
                :rules="summaryRules"
                type="text"
                label="Summary"
              />
            </v-list-item>
            <v-list-item>
              <v-textarea
                v-model="description"
                :disabled="submitting"
                :rules="descriptionRules"
                label="Description"
                auto-grow
              />
            </v-list-item>
            <v-list-item>
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    :rules="dateRule"
                    :disabled="submitting"
                    label="Start Date"
                    append-icon="$calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="startDate"
                  :min="minDate"
                  @input="startDateMenu = false"
                />
              </v-menu>
              <v-menu
                v-if="!isAllDay"
                v-model="startTimeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startTime"
                    :rules="timeRule"
                    :disabled="submitting"
                    class="ml-4"
                    label="Start Time"
                    append-icon="$clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-model="startTime"
                  format="24hr"
                  @input="startTimeMenu = false"
                />
              </v-menu>
            </v-list-item>
            <v-list-item>
              <v-menu
                v-model="stopDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="stopDate"
                    :rules="dateRule"
                    :disabled="submitting"
                    label="Stop Date"
                    append-icon="$calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="stopDate"
                  :min="minDate"
                  @input="stopDateMenu = false"
                />
              </v-menu>
              <v-menu
                v-if="!isAllDay"
                v-model="stopTimeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="stopTime"
                    :rules="timeRule"
                    :disabled="submitting"
                    class="ml-4"
                    label="Stop Time"
                    append-icon="$clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-model="stopTime"
                  format="24hr"
                  @input="stopTimeMenu = false"
                />
              </v-menu>
            </v-list-item>
            <v-list-item>
              <v-switch
                v-model="isAllDay"
                :disabled="submitting"
                label="All Day"
                persistent-hint
              />
            </v-list-item>
            <v-list-item v-if="allowPublic">
              <v-switch
                v-model="isPublic"
                :disabled="submitting"
                label="Public"
                :hint="publicHelp"
                persistent-hint
              />
            </v-list-item>
          </v-list>
          <div v-if="allowAttendees">
            <v-card-title>
              Invite Attendees
            </v-card-title>
            <v-card-text>
              <ProfileMultiSelect
                v-model="usernameList"
                :disabled="submitting"
              />
            </v-card-text>
          </div>
          <div v-if="allowBanner">
            <v-card-title>
              Banner
            </v-card-title>
            <v-card-text>
              <div
                class="image-dropbox"
                :class="{'image-selected':(image),'input-error':(imageError)}"
              >
                <input
                  id="image"
                  class="image-input"
                  type="file"
                  accept="image/heic,image/heif,image/jpeg,image/png,.heic,.heif,.jpeg,.jpg,.png"
                  :disabled="submitting"
                  @change="imageInput($event)"
                >
                <div
                  v-if="!image"
                  class="text-center"
                >
                  <v-icon
                    size="100"
                    color="primary"
                  >
                    $plus
                  </v-icon>
                  <p class="description">
                    Upload a new banner.
                  </p>
                </div>
                <div
                  v-if="imagePreview"
                  align="center"
                  class="imagePreview"
                >
                  <v-btn
                    icon
                    class="clearInput"
                    @click="clearImage"
                  >
                    <v-icon>
                      $close
                    </v-icon>
                  </v-btn>
                  <v-img
                    :src="imagePreview.src"
                    height="300"
                    contain
                  />
                </div>
              </div>
              <div
                v-if="imageError"
                class="hint error-message"
              >
                {{ imageError }}
              </div>
              <div
                v-else
                class="hint"
              >
                <span v-if="imageLabel">{{ imageLabel }}</span>
                <span v-else>Images should be JPG, PNG, HEIC, or HEIF and no larger than 13MB.</span>
              </div>
              <stock-image-chooser
                v-if="!image"
                @selected="setStockImageID"
              />
            </v-card-text>
          </div>
          <v-card-actions>
            <v-btn
              :disabled="submitting"
              type="submit"
              color="primary"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import ProfileMultiSelect from '@/components/ProfileMultiSelect.vue'
import StockImageChooser from '@/components/StockImageChooser.vue'
import { allianceAPI } from '@/api'
import { checkImageValue, imageTimeout } from '@/utils/images'
import { eventDescriptionRules } from '@/utils/rules'
import { formatBytes } from '@/utils/format'
import { mapState } from 'vuex'
import { parseResponseErrors } from '@/utils/errors'

export default {
  components: {
    ProfileMultiSelect,
    StockImageChooser,
  },
  props: {
    team: {
      type: Object,
      required: false,
      default: null,
    },
    event: {
      type: Object,
      required: false,
      default: null,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    allowAttendees: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function() {
    return {
      description: '',
      descriptionRules: eventDescriptionRules,
      errorMessages: [],
      image: null,
      imageError: false,
      imageLabel: null,
      imagePreview: null,
      isAllDay: true,
      isPublic: false,
      loading: false,
      minDate: moment().format('YYYY-MM-DD'),
      startDate: null,
      startDateMenu: null,
      startTime: null,
      startTimeMenu: null,
      stopDate: null,
      stopDateMenu: null,
      stopTime: null,
      stopTimeMenu: null,
      submitting: false,
      success: null,
      stockImageID: null,
      summary: '',
      summaryRules: [
        v => !!v || 'Field is required',
        v => v && v.length <= 250 || 'Please restrain yourself to 250 characters',
      ],
      dateRule: [
        v => !!v || 'Field is required',
      ],
      usernameList: [],
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
    }),
    publicHelp() {
      if (this.team) {
        return "When enabled, people who are not members of the team may view and join the event."
      }
      return "When enabled, people who have not been invited may view and join the event."
    },
    timeRule() {
      const rules = []
      if (!this.isAllDay) {
        rules.push(v => !!v || 'Field is required')
      }
      return rules
    },
    allowBanner() {
      if (this.event && !this.event.quest) {
        return true
      }
      return false
    },
    allowPublic() {
      if (this.team && !this.team.allow_public_events) {
        return false
      }
      if (this.event && this.event.quest) {
        return false
      }
      return true
    },
    startAt() {
      let startTime = this.startTime
      if (!startTime || this.isAllDay) {
        startTime = "00:00:00"
      }
      if (this.startDate) {
        return moment(`${this.startDate}T${startTime}`).format()
      }
      return null
    },
    stopAt() {
      let stopTime = this.stopTime
      if (!stopTime || this.isAllDay) {
        stopTime = "23:59:00"
      }
      if (this.stopDate) {
        return moment(`${this.stopDate}T${stopTime}`).format()
      }
      return null
    },
  },
  watch: {
    visible: function (value) {
      if (value) {
        this.init()
      }
    },
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init: function () {
      this.errorMessages = []
      this.loading = false
      this.stockImageID = null
      this.submitting = false
      this.success = null
      this.usernameList = []
      if (this.event) {
        this.description = this.event.description
        this.isAllDay = this.event.is_all_day
        this.isPublic = this.event.is_public
        this.startDate = moment(this.event.start_at).format('YYYY-MM-DD')
        this.startTime = moment(this.event.start_at).format('HH:mm')
        this.stopDate = moment(this.event.stop_at).format('YYYY-MM-DD')
        this.stopDate = moment(this.event.stop_at).format('YYYY-MM-DD')
        this.stopTime = moment(this.event.stop_at).format('HH:mm')
        this.summary = this.event.summary
      }
    },
    imageInput: function(event) {
      let valid = checkImageValue(event.target.files[0])
      if (valid === true) {
        this.imageError = false
        this.image = event.target.files[0]
        this.imageLabel = `${this.image.name} (${formatBytes(this.image.size)})`
        this.imagePreview = new Image()
        this.imagePreview.src = window.URL.createObjectURL(this.image)
      } else {
        this.image = null
        this.imagePreview = null
        this.imageError = valid
      }
    },
    clearImage() {
      this.image = null
      this.imagePreview = null
      this.imageLabel = null
      this.imageError = false
    },
    setStockImageID(id) {
      this.stockImageID = id
    },
    submit: function () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.submitting = true
      this.success = null
      this.errorMessages = []
      const data = new FormData()
      data.append('description', this.description)
      data.append('is_all_day', this.isAllDay)
      data.append('start_at', this.startAt)
      data.append('stop_at', this.stopAt)
      data.append('summary', this.summary)
      if (this.team) {
        data.append('team', this.team.id)
      }
      if (this.allowPublic) {
        data.append('is_public', this.isPublic)
      }
      if (this.allowAttendees) {
        for (let i = 0; i < this.usernameList.length; i++) {
          data.append('invitees', this.usernameList[i]);
        }
      }
      if (this.allowBanner && this.image) {
        data.append('image', this.image)
      } else if (this.allowBanner && this.stockImageID) {
        data.append('stock_image', this.stockImageID)
      }
      let url = '/quest/event/'
      let method = 'post'
      let successType = 'Created'
      if (this.event) {
        url = `/quest/event/${this.event.id}/`
        method = 'patch'
        successType = 'Edited'
      }
      return allianceAPI[method](url, data, { timeout: imageTimeout })
        .then((response) => {
          this.$refs.form.reset()
          this.$emit('eventSubmitted', response.data)
          this.success = successType
          if (successType == 'Created') {
            this.$router.push({ name: 'Event', params: { id: response.data.id  }})
          }
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = null
        })
        .finally(() => this.submitting = false)
    },
  },
}
</script>
