<template>
  <span class="multiselect-list">
    <v-combobox
      v-model="selectedProfileList"
      :items="profileList"
      :disabled="disabled"
      :rules="rules"
      :search-input.sync="searchInput"
      item-text="user"
      item-value="user"
      :label="label"
      chips
      multiple
      :menu-props="{closeOnContentClick: true}"
      @change="searchInput=''"
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          :disabled="data.disabled"
        >
          <v-avatar
            v-if="data.item.user"
            left
          >
            <v-img :src="data.item.avatar_thumbnail_url" />
          </v-avatar>
          <span
            v-if="data.item.user"
            class="pr-2"
          >
            {{ data.item.user }}
          </span>
          <span
            v-else
            class="pr-2"
          >
            {{ data.item }}
          </span>
          <v-icon
            v-if="canDeselect(data.item)"
            small
            @click="data.parent.selectItem(data.item)"
          >
            $close
          </v-icon>
        </v-chip>
      </template>
    </v-combobox>
  </span>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    defaultUsernameList: {
      type: Array,
      required: false,
      default: () => [],
    },
    label: {
      type: String,
      required: false,
      default: 'Members',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function() {
    return {
      searchInput: '',
      selectedProfileList: [],
      rules: [
        v => !!v || 'Field is required',
        v => v.length <= 24 || 'Please restrain yourself to 24 recipients',
      ],
    }
  },
  computed: {
    ...mapState({
      profileList: state => state.profiles.all,
    }),
  },
  watch: {
    selectedProfileList: function () {
      this.$emit('input', this.getSelectedUsernames())
    },
    defaultUsernameList: function () {
      this.selectDefaultProfiles()
    },
  },
  created: function () {
    this.selectDefaultProfiles()
  },
  methods: {
    canDeselect(item) {
      if (this.defaultUsernameList.indexOf(item.user) >= 0) {
        return false
      }
      if (this.defaultUsernameList.indexOf(item) >= 0) {
        return false
      }
      return true
    },
    selectDefaultProfiles() {
      if (this.defaultUsernameList) {
        for (let i = 0; i < this.defaultUsernameList.length; i++) {
          let username = this.defaultUsernameList[i]
          let profile = this.$store.getters['profiles/getByAttribute']({key: 'user', value: username})
          if (profile && this.selectedProfileList.indexOf(profile) < 0) {
            this.selectedProfileList.push(profile)
          } else if (!profile && this.selectedProfileList.indexOf(username) < 0) {
            this.selectedProfileList.push(username)
          }
        }
      }
    },
    getSelectedUsernames() {
      const usernames = []
      for (let i = 0; i < this.selectedProfileList.length; i++) {
        const entry = this.selectedProfileList[i]
        if (typeof entry === 'string') {
          usernames.push(entry)
        } else {
          usernames.push(entry.user)
        }
      }
      return usernames
    },
  }
}
</script>
