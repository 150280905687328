<template>
  <div class="logbook">
    <h1
      class="page-title"
    >
      Logbook
    </h1>
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab>
        <v-icon
          small
          class="mr-4"
        >
          $quest
        </v-icon> Active ({{ activeList.length }})
      </v-tab>
      <v-tab>
        <v-icon
          small
          class="mr-4"
        >
          $quest
        </v-icon> Saved ({{ savedList.length }})
      </v-tab>
      <v-tab>
        <v-icon
          small
          class="mr-4"
        >
          $quest
        </v-icon> Complete ({{ completeList.length }})
      </v-tab>
      <v-tab v-if="profile.num_invited_events">
        <v-icon
          small
          class="mr-4"
        >
          $calendar
        </v-icon> Invited ({{ profile.num_invited_events }})
      </v-tab>
      <v-tab v-if="profile.num_confirmed_events">
        <v-icon
          small
          class="mr-4"
        >
          $calendar
        </v-icon> Upcoming ({{ profile.num_confirmed_events }})
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
    >
      <v-tab-item>
        <participant-list :participant-quest-list="activeList" />
      </v-tab-item>
      <v-tab-item>
        <participant-list :participant-quest-list="savedList" />
      </v-tab-item>
      <v-tab-item>
        <participant-list :participant-quest-list="completeList" />
      </v-tab-item>
      <v-tab-item v-if="profile.num_invited_events">
        <EventList url="/quest/event/?status=invited" />
      </v-tab-item>
      <v-tab-item v-if="profile.num_confirmed_events">
        <EventList url="/quest/event/?status=confirmed" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import EventList from '@/components/EventList.vue'
import ParticipantList from '@/components/ParticipantList.vue'
import { mapState } from 'vuex'

export default {
  components: {
    ParticipantList,
    EventList,
  },
  data: function() {
    return {
      tab: null,
    }
  },
  computed: {
    ...mapState({
      participant_list: state => state.participants.all,
      profile: state => state.auth.profile,
    }),
    savedList() {
      return this.getParticipantList(['saved'])
    },
    activeList() {
      return this.getParticipantList(['active', 'rejected'])
    },
    completeList() {
      return this.getParticipantList(['complete', 'pending'])
    },
  },
  created: function () {
    this.$store.dispatch('auth/getProfile')
    this.$store.dispatch('quests/getAll')
    this.$store.dispatch('participants/getAll')
  },
  methods: {
    getParticipantList(statusList) {
      const result = []
      for (let i=0; i < this.participant_list.length; i++) {
        const participant = this.participant_list[i]
        if (statusList.indexOf(participant.status) == -1) {
          continue
        }
        const quest = this.$store.getters['quests/getByID'](participant.quest.id) || null
        if (quest) {
          result.push({
            id: participant.id,
            participant: participant,
            quest: quest,
          })
        }
      }
      return result
    },
  },
}
</script>
