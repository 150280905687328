<template>
  <div>
    <Loading
      v-if="showLoading"
      :loading="loading"
      message="Loading Events"
    />
    <div v-else>
      <v-card-actions v-if="allowFiltering && !loading">
        <v-dialog
          v-model="filterDialog"
          :fullscreen="$vuetify.breakpoint.smAndDown"
          :max-width="$vuetify.breakpoint.thresholds.sm"
        >
          <v-card>
            <v-toolbar>
              <v-row align="center">
                <v-col>
                  <v-btn
                    icon
                    @click="filterDialog = false"
                  >
                    <v-icon>
                      $close
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col class="text-center">
                  <v-toolbar-title>
                    Filter
                  </v-toolbar-title>
                </v-col>
                <v-col class="text-right">
                  <v-btn
                    text
                    color="primary"
                    @click="resetFilterSelections"
                  >
                    Reset
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
            <v-form
              ref="form"
              @submit.prevent="filterEvents"
            >
              <v-card-text>
                <v-select
                  v-model="filterRegion"
                  :items="regionList"
                  item-text="name"
                  item-value="id"
                  label="Region"
                  clearable
                />
                <v-menu
                  v-model="filterDateFromMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filterDateFrom"
                      label="Date From"
                      append-icon="$calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="filterDateFrom"
                    @input="filterDateFromMenu = false"
                  />
                </v-menu>
                <v-menu
                  v-model="filterDateToMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="filterDateTo"
                      label="Date From"
                      append-icon="$calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="filterDateTo"
                    @input="filterDateToMenu = false"
                  />
                </v-menu>
              </v-card-text>
              <v-card-subtitle>
                Filter by distance from current location.
              </v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="filterDistance"
                      label="Distance"
                      type="number"
                      :error-messages="filterDistanceError"
                    />
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="filterDistanceUnit"
                      :items="distanceUnits"
                      label="Unit"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  color="primary"
                  type="submit"
                >
                  Show Filter Results
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-btn @click.stop="filterDialog = true">
          <v-icon>
            $filter
          </v-icon>
        </v-btn>
      </v-card-actions>
      <v-card
        v-if="error"
        flat
      >
        <v-card-text>
          Failed to load events.
        </v-card-text>
      </v-card>
      <v-card
        v-else-if="eventList.length < 1 && !loading"
        flat
      >
        <v-card-text>
          No events found.
        </v-card-text>
      </v-card>
      <v-row v-if="eventList">
        <v-col
          v-for="event in eventList"
          :key="event.id"
          cols="12"
          md="6"
          xl="4"
        >
          <v-sheet min-height="200">
            <v-lazy
              v-model="event.isActive"
              :options="{ threshold: .2 }"
            >
              <EventTile :event="event" />
            </v-lazy>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            v-if="nextPage"
            :disabled="loading"
            :block="true"
            @click="loadEvents"
          >
            Load More
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            v-if="canCreate"
            color="primary"
            :disabled="loading"
            :block="true"
            @click.stop="createDialog = true"
          >
            Create Event
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-if="canCreate"
      v-model="createDialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :max-width="$vuetify.breakpoint.thresholds.sm"
    >
      <v-card>
        <v-toolbar>
          <v-btn
            icon
            dark
            @click="createDialog = false"
          >
            <v-icon>
              $close
            </v-icon>
          </v-btn>
          <v-toolbar-title>
            <span v-if="team">{{ team.name }}: </span>Create Event
          </v-toolbar-title>
        </v-toolbar>
        <EventForm
          :team="team"
          :visible="createDialog"
          @eventSubmitted="addEvent($event)"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import EventForm from '@/components/EventForm.vue'
import EventTile from '@/components/EventTile.vue'
import { allianceBaseURL, allianceAPI } from '@/api'
import { mapState } from 'vuex'

export default {
  components: {
    Loading,
    EventForm,
    EventTile,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    maxHeight: {
      type: String,
      default: '',
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    allowFiltering: {
      type: Boolean,
      default: false,
    },
    team: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: function() {
    return {
      createDialog: false,
      distanceUnits: ["km", "miles"],
      error: false,
      eventList: [],
      filterDateFrom: null,
      filterDateFromMenu: false,
      filterDateTo: null,
      filterDateToMenu: false,
      filterDialog: false,
      filterDistance: null,
      filterDistanceError: null,
      filterDistanceKm: null,
      filterDistanceUnit: "km",
      filterRegion: null,
      loading: false,
      nextPage: null,
      showLoading: false,
    }
  },
  computed: {
    ...mapState({
      regionList: state => state.regions.all,
      userCoordinates: state => state.userlocation.coordinates,
    }),
    filterQuerystring() {
      let querystring = ''
      if (this.filterRegion) {
        querystring += `region=${this.filterRegion}&`
      }
      if (this.filterDateFrom) {
        querystring += `date_from=${this.filterDateFrom}&`
      }
      if (this.filterDateTo) {
        querystring += `date_to=${this.filterDateTo}&`
      }
      if (this.filterDistanceKm && this.userCoordinates) {
        querystring += `distance=${this.filterDistanceKm}&lat=${this.userCoordinates.lat}&lng=${this.userCoordinates.lng}&`
      }
      if (querystring) {
        if (this.url.endsWith('/')) {
          querystring = `?${querystring}`
        } else {
          querystring = `&${querystring}`
        }
      }
      return querystring
    },
  },
  created: function () {
    this.loadEvents()
  },
  methods: {
    resetFilterSelections() {
      this.filterDateFrom = null
      this.filterDateTo = null
      this.filterDistance = null
      this.filterDistanceError = null
      this.filterDistanceKm = null
      this.filterRegion = null
    },
    eventProfileList(event) {
      const profileList = []
      for (let i = 0; i < event.attendees.length; i++) {
        const attendee = event.attendees[i]
        if (attendee.status == "confirmed") {
          profileList.push(attendee.profile)
        }
      }
      return profileList
    },
    async filterEvents() {
      this.filterDistanceError = null
      this.filterDistanceKm = null
      if (this.filterDistance) {
        let distance = parseInt(this.filterDistance)
        if (isNaN(distance)) {
          this.filterDistanceError = "Please enter a number."
          return
        }
        if (this.filterDistanceUnit == "km" && distance > 600) {
          this.filterDistanceError = "Please limit yourself to 600 km."
          return
        }
        if (this.filterDistanceUnit == "miles" && distance > 372) {
          this.filterDistanceError = "Please limit yourself to 372 miles."
          return
        }
        if (this.filterDistanceUnit == "miles") {
          distance = distance * 1.609
        }
        this.filterDistanceKm = distance
        await this.$store.dispatch('userlocation/get')
        if (!this.userCoordinates) {
          this.filterDistanceError = "Failed to fetch current location."
          return
        }
      }
      this.filterDialog = false
      this.loadEvents()
    },
    loadEvents() {
      this.error = false
      this.loading = true
      let url = this.url + this.filterQuerystring
      if (this.nextPage) {
        url = this.nextPage
      } else {
        this.showLoading = true
      }
      return allianceAPI.get(url)
        .then((response) => {
          if (this.nextPage) {
            this.eventList.push(...response.data.results)
          } else {
            this.eventList = response.data.results
          }
          if (response.data.next) {
            this.nextPage = response.data.next.replace(allianceBaseURL, '')
          } else {
            this.nextPage = null
          }
        })
        .catch(() => this.error = true)
        .finally(() => {
          this.loading = false
          this.showLoading = false
        })
    },
    addEvent(event) {
      this.eventList.unshift(event)
    },
  },
}
</script>
