<template>
  <v-card>
    <v-card-text>
      <div v-if="!participantQuestList.length">
        No Quests found.
      </div>
      <v-row>
        <v-col
          v-for="participantQuest in participantQuestList"
          :key="participantQuest.id"
          cols="12"
          md="6"
          xl="4"
        >
          <QuestTile
            :quest="participantQuest.quest"
            :participant="participantQuest.participant"
            :to="{ name: 'Participant', params: { id: participantQuest.id }}"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import QuestTile from '@/components/QuestTile.vue'

export default {
  components: {
    QuestTile,
  },
  props: {
    participantQuestList: {
      type: Array,
      required: true,
    }
  },
}
</script>
