<template>
  <v-card
    outlined
    :to="{ name: 'Event', params: { id: event.id }}"
    class="mb-4"
  >
    <v-img
      :src="(event.image) ? event.image : ''"
      height="200px"
      class="px-3"
    >
      <v-row class="mb-7">
        <v-col>
          <div class="headline">
            {{ event.summary }}
          </div>
          <div class="title">
            {{ event.start_at|formatDate }}
          </div>
        </v-col>
      </v-row>
      <v-row class="align-end pt-7">
        <v-col>
          <ProfileAvatarList :profile-list="eventProfileList(event)" />
        </v-col>
      </v-row>
    </v-img>
  </v-card>
</template>

<script>
import ProfileAvatarList from '@/components/ProfileAvatarList.vue'

export default {
  components: {
    ProfileAvatarList,
  },
  props: {
    event: {
      type: Object,
      required: true,
    }
  },
  methods: {
    eventProfileList(event) {
      const profileList = []
      for (let i = 0; i < event.attendees.length; i++) {
        const attendee = event.attendees[i]
        if (attendee.status == "confirmed") {
          profileList.push(attendee.profile)
        }
      }
      return profileList
    },
  },
}
</script>
