<template>
  <div>
    <div v-if="stockImageList.length">
      <p class="mt-4">
        Optionally choose a stock image.
      </p>
      <v-row>
        <v-col
          v-for="image in stockImageList"
          :key="image.id"
          cols="6"
          md="4"
        >
          <v-sheet>
            <v-lazy
              v-model="image.isActive"
              :options="{ threshold: .2 }"
            >
              <v-card
                outlined
                class="mb-4"
                @click="selectImage(image)"
              >
                <v-img
                  :src="image.image_banner"
                  class="px-3"
                >
                  <div
                    v-if="selectedImageID == image.id"
                    class="tile-overlay text-h5"
                  >
                    <v-icon x-large>
                      $check
                    </v-icon>
                  </div>
                </v-img>
              </v-card>
            </v-lazy>
          </v-sheet>
        </v-col>
      </v-row>
      <v-btn
        v-if="nextPage"
        @click="loadImages"
      >
        Load More Images
      </v-btn>
    </div>
  </div>
</template>

<script>
import { allianceBaseURL, allianceAPI } from '@/api'

export default {
  data: function() {
    return {
      loadingError: false,
      nextPage: null,
      stockImageList: [],
      selectedImageID: null,
    }
  },
  mounted: function () {
    this.loadImages()
  },
  methods: {
    selectImage: function (image) {
      if (this.selectedImageID == image.id) {
        this.selectedImageID = null
      } else {
        this.selectedImageID = image.id
      }
      this.$emit('selected', this.selectedImageID)
    },
    loadImages: function () {
      let url = '/alliance/stock-image/'
      if (this.nextPage) {
        url = this.nextPage
      }
      return allianceAPI.get(url)
        .then((response) => {
          if (this.nextPage) {
            this.stockImageList.push(...response.data.results)
          } else {
            this.stockImageList = response.data.results
          }
          if (response.data.next) {
            this.nextPage = response.data.next.replace(allianceBaseURL, '')
          } else {
            this.nextPage = null
          }
        })
        .catch(() => this.loadingError = true)
    },
  },
}
</script>
