<template>
  <div class="avatar-list">
    <router-link
      v-for="profile in displayList"
      :key="profile.id"
      :to="{ name: 'SocialProfile', params: { username: profile.user }}"
    >
      <v-avatar
        size="24"
        class="mr-1"
      >
        <img
          :src="profile.avatar_display_url"
          :alt="profile.user + ' avatar'"
        >
      </v-avatar>
    </router-link>
    <span
      v-if="remainder"
      class="remainder"
    >
      +{{ remainder }} more
    </span>
  </div>
</template>

<script>

export default {
  props: {
    profileList: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  computed: {
    displayList() {
      return this.profileList.slice(0, this.limit)
    },
    remainder() {
      const remainder = this.profileList.length - this.limit
      if (remainder > 0) {
        return remainder
      }
      return 0
    }
  },
}
</script>
